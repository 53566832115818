import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
// const localBaseUrl = process.env.REACT_APP_BASE_URL_LOCAL;
const invoiceDelete = process.env.REACT_APP_INVOICE;

const deleteInvoice = async(id) => {
    const authToken = auth();
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {

        const result = await axios.delete(`${baseUrl}${invoiceDelete}/${id}`, config);
        return result;

    } catch(err) {
        console.log('error', err);
    }
}

export const deleteInvoiceByItemId = async(id, rowId) => {
    const authToken = auth();
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {

        const result = await axios.delete(`${baseUrl}${invoiceDelete}?invoiveId=${id}&rowId=${rowId}`, config);
        return result;

    } catch(err) {
        console.log('error', err);
    }
}

export default deleteInvoice;