import React from 'react'

const Footer = () => {
    return (
        <>
            <footer>
                <div className='term-condition'>
                    <p><b>Terms and Conditions :</b></p>
                    <ul>
                        <li>1. Subject to Ahmedabad jurisdiction.</li>
                        <li>2. Buy Or Sell as per that present day market Rate.</li>
                        <li>3. Order, once placed can not be cancelled under any circumstances.</li>
                        <li>4. Goods sold are in good condition and need to be handled carefully. The firm will not be responsible for any damage henceforth.</li>
                        <li>5. The firm will not be responsible for purity of goods if their form has been changed.</li>
                        <li>6. The original Tax Invoice has to be submitted at the time of exchange, sales return</li>
                        <li>7. No any guarantee/ warrenty on imitation jewellery and also its platting.</li>
                    </ul>
                </div>
                <div className='float-text'>
                    <div className='invoice-signature'>
                        <p><b>Customer Signatory</b></p>
                        <p className='invoice-signaturep2'><b>Authorized Signatory</b></p>
                    </div>
                </div>
                {/* <p className='footer pt-5'></p> */}
            </footer>
        </>
    )
}

export default Footer;
