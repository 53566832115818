import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const invoiceEndpoint = process.env.REACT_APP_CREATE_INVOICE;

const createInvoice = async(data) => {

    const authToken = auth();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.post(`${baseUrl}${invoiceEndpoint}`, JSON.stringify(data), config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;

    }
}

export default createInvoice;
