import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import img from "../images/left-side.jpg";
// import logo from "../images/xweb-white-logo.png";
// import logo from "../images/logo2.png"
import logo from "../images/logonew.png";
import Swal from 'sweetalert2';
import { ShowErrorToast } from '../component/error/Toast';

const baseUrl = process.env.REACT_APP_BASE_URL;
const loginEndPoint = process.env.REACT_APP_LOGIN_ENDPOINT;


const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    // let keyEnter;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
    }

    // useEffect(() => {
    //     keyEnter = event => {
    //         if (event.key === 'Enter') {
    //             event.preventDefault()
    //             handleChange()
    //         }
    //     }

    //     document.addEventListener('keydown', keyEnter);
    // return () => {
    //     document.removeEventListener('keydown', keyEnter)
    // }
    // }, []);

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validate = (mailId) => {

        if (validateEmail(mailId)) {
            return true;
        } else {
            return false;
        }
    }

    const validatePassword = (password) => {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-+.]).{6,20}$/;
        return regex.test(password);
    }

    const handleChange = async (e) => {

        e.preventDefault();
        const data = {
            email: email,
            password: passwordInput
        };
        if (data.email === "") {
            return ShowErrorToast(`Please enter your mail!`);
        }

        const validationMail = validate(data.email);

        if (validationMail === false) {
            return ShowErrorToast(`Please check you email id!`);
        }

        const passValidation = validatePassword(data.password);
        if (passValidation === false) {
            return ShowErrorToast(`Please check your password, password should contain 8 characters, One Special Character, One Number, One Capital letter and Small letter!`);
        }

        if (!data.email) {
            return ShowErrorToast(`Email is required!!`)
        }
        if (!data.password) {
            return ShowErrorToast(`itemPrice can not be empty!`)
        }

        try {

            const result = await axios.post(`${baseUrl}${loginEndPoint}`, data, config);
            if (result.data.status === 200) {
                localStorage.setItem('token', result.data.data.token)

                Swal.fire({
                    title: 'Login Successfull',
                    icon: 'success',
                    showCloseButton: true,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/home')
                    }
                })

            }

        } catch (err) {
            console.log(err, 'mmmmmm');
            // Swal.fire({
            //     title: err.response.data.message,
            //     confirmButtonText: 'Ok'
            // })
        }

    };




    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }

    // const togglePassword = () => {
    //     if (passwordType === "password") {
    //         setPasswordType("text")
    //         return;
    //     }
    //     setPasswordType("password")
    // }

    return (
        <div className='login-body'>
            <section>
                <div className="row">
                    <div className="col-6">
                        <img src={img} className='sideimg' alt='Shop' />
                    </div>
                    <div className="col-6 d-flex justify-content-center maindiv-form">
                        <div className='login col-6' id='login-form'>
                            <div className='logoimgs'>
                                <img className='logo' src={logo} alt='Login' />
                            </div>
                            <form className="login100-form validate-form" onSubmit={(e) => handleChange(e)}>
                                <span className="login100-form-title d-flex justify-content-center mb-5">
                                    <h3 className='login-font-style'>Login to your Account</h3>
                                </span>
                                <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                    <input type="text" className='input100' id="id" name="email" placeholder='Email' onChange={e => setEmail(e.target.value)} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div className="wrap-input100 validate-input" data-validate="Password is required">
                                    <input type={passwordType} className='input100' id="password-field" name="password" placeholder='Password...' onChange={handlePasswordChange} />

                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div className="container-login100-form-btn d-flex justify-content-center">
                                    {/* <button type="button" className="btn btn-warning login100-form-btn" onClick={(e) => handleChange(e)}>Login</button> */}
                                    <input className="btn btn-warning submit-btn login100-form-btn"
                                        type="submit"
                                        onClick={(e) => handleChange(e)}
                                        value="Login" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
};

export default Login;
