import React from 'react';
import { useNavigate } from 'react-router-dom';
import Img1 from "../images/saleimg.png";
import Img2 from "../images/saleimg2.png";

const Sale = () => {

  const navigate = useNavigate();

  const redirectToInvoicePage = () => {
    navigate('/invoice');
  }

  const redirectToReportPage = () => {
    navigate('/report')
  }


  return (
    <>
    
      <section id="salepage">
        <div className="container">
          <div className='row d-flex justify-content-center'>

            <div className="col-5 m-1 img1">

              <div>
                <img className='saleimg' src={Img1} alt='sale' />
              </div>
              <button type="button" className="btn btn-warning salebtn salebtn1 login100-form-btn" onClick={() => redirectToInvoicePage()}>Sale</button>
            </div>

            <div className="col-5 m-1 img1">
              <div>
                <img className='saleimg2' src={Img2} alt='sale' />
              </div>
              <button type="button" className="btn btn-warning salebtn salebtn2 login100-form-btn" onClick={() => redirectToReportPage()}>Sales report</button>
            </div>

          </div>
        </div>
      </section>
    </>
  )
};

export default Sale;
