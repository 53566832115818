import React from "react";
import logo from "../images/logo.png";
import logo2 from "../images/logo2.png";
// import Newlogo from "../images/logonew.png"
import person from "../images/person.png";
// import logo from "../images/xweb-white-logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate("/home");
    }

    const redirectToInvoice = () => {
        navigate("/invoice");
    }

    const redirectToReport = () => {
        navigate("/report");
    }

    const logOut = () => {
        localStorage.removeItem("token");
        navigate("/")
    }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between header">
                <div className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none">
                    <div className='salelogoimg navbar-brand'>
                        <img className='salelogo' src={logo} alt='logo image' />
                        <img className='salelogo2' src={logo2} alt='logo image' />
                    </div>
                </div>
                <div className="col-12 col-lg-auto mb-3 mb-lg-0 sideDiv">
                    <div className="dropdown">
                        <div className="sideCircle">
                            <img src={person} className="personImg" />
                        </div>

                        <button className="btn btn-secondary dropdown-toggle adminbtn login100-form-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Admin
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" onClick={redirectToHome}>Home</a></li>
                            <li><a className="dropdown-item" onClick={redirectToInvoice}>Sale</a></li>
                            <li><a className="dropdown-item" onClick={redirectToReport}>Report</a></li>
                            <li><a className="dropdown-item" onClick={logOut} >LogOut</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;
