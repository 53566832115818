import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const userSearchUrl = process.env.REACT_APP_SEARCH_USER;

const userListByName = async(name) => {
    const authToken = auth();
    const config = {
        headers: {
            authorization: authToken
        }
    };

    try {
        const result = await axios.get(`${baseUrl}${userSearchUrl}?search=${name}`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export default userListByName;
