import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import Header from "../component/header";
import Footer from "../component/footer";
import auth from "../utils/auth";


const ProtectedRoutes = () => {
    const authToken = auth()

    return (

        <div>
            {authToken ? <>
                <Header />
                <Outlet />
                <Footer />
            </> : <>
                <Navigate to='/' />
            </>}
        </div>
    );
}

export default ProtectedRoutes;
