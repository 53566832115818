import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_BASE_URL;
const createUser = process.env.REACT_APP_CREATE_USER;

const ModalPopup = ({ getStatusUpdate }) => {

    let name, value;
    const [show, setShow] = useState(false);
    const [user, setUser] = useState({
        name: "",
        dob: "",
        city: "",
        address: "",
        mobileNo: ""
    });

    const handleInput = (e) => {
        name = e.target.name
        value = e.target.value
        setUser({ ...user, [name]: value })
    }

    const submithandle = async () => {

        if (!user.name) {
            Swal.fire({
                title: 'Name field is required',
                confirmButtonText: 'Ok'
            })
        }
        if (!user.dob) {
            Swal.fire({
                title: 'Birthdate field is required',
                confirmButtonText: 'Ok'
            })
        }
        if (!user.address) {
            Swal.fire({
                title: 'Address field is required',
                confirmButtonText: 'Ok'
            })
        }
        if (!user.mobileNo) {
            Swal.fire({
                title: 'mobileNo. field is required',
                confirmButtonText: 'Ok'
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const userdata = await axios.post(`${baseUrl}${createUser}`, JSON.stringify(user), config)
            if(userdata.data.status === 200) {
                getStatusUpdate('invoicePage');
                Swal.fire({
                    title: 'User Added!',
                    confirmButtonText: 'Ok'
                })
                handleClose();
                setUser({
                    name: "",
                    dob: "",
                    city: "",
                    address: "",
                    mobileNo: ""
                });
            }
        } catch (err) {
            console.log('error', err);
            Swal.fire({
                title: err.response.data.message,
                confirmButtonText: 'Ok'
            })
        }
    }

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => setShow(true);

    return (
        <>
            <Button className='add' variant="primary" onClick={handleShow}>
                Add
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='pop-up' closeButton>
                    <Modal.Title className='pop-up_title'> New Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={user.name}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">DOB</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    value={user.dob}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>

                        </Form.Group>
                        <Form.Group as={Row}
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label column sm="2">address</Form.Label>
                            <Col sm="10">
                                <Form.Control value={user.address} name="address" onChange={handleInput} as="textarea" rows={3} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">City</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={user.city}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Mobile</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="mobileNo"
                                    value={user.mobileNo}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='pop-upbtn' type="submit" variant="primary" onClick={submithandle}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalPopup;